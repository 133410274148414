// LAYOUT
// ----------------------------------------------------------------------

export const NETWORK = "Terra Classic"
export const SB_STAKE_URL = "https://station.terraclassic.community/validator/terravaloper19ne0aqltndwxl0n32zyuglp2z8mm3nu0gxpfaw";
export const NETWORK_ICON = 'terraclassic.svg'
export const EXPLORER_OPERATOR_URL = 'https://finder.terraclassic.community/mainnet/validator'
export const EXPLORER_ACCOUNT_URL = 'https://finder.terraclassic.community/mainnet/account'
export const EXPLORER_ADDRESS_URL = 'https://finder.terraclassic.community/mainnet/address'
export const EXPLORER_TX_URL = 'https://finder.terraclassic.community/mainnet/tx'
export const GOV_PROP_URL = 'https://station.terraclassic.community/proposal/columbus-5'
export const PROD_API_URL = "";

export const NETWORK_OPTIONS = [
  { label: 'Terra', link: 'https://terra.stakebin.io' },
  { label: 'Terra Classic', link: 'https://terraclassic.stakebin.io' },
];

export const BC_HREF_LINKS = {
  "Terra": "/terra",
  "Validators": "/terra/validators",
}

export const HEADER = {
  MOBILE_HEIGHT: 48,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 76,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 76 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const KNOWN_ACCOUNT_ADDRESSES = {
  terra1fl48vsnmsdzcv85q5d2q4z5ajdha8yu3nln0mh: "Bonded Tokens Pool",
  terra1tygms3xhhs3yv487phx3dw4a95jn7t7l8l07dr: "Unbonding Tokens Pool",
  terra1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8pm7utl: "Distribution Module",
  terra1jgp27m8fykex4e4jtt0l7ze8q528ux2lh4zh0f: "Oracle Pool",
  terra10atxpzafqfjy58z0dvugmd9zf63fycr6uvwhjm: "Binance 1",
  terra1jrq7xa63a4qgpdgtj70k8yz5p32ps9r7mlj3yr: "Binance 2",
  terra15s66unmdcpknuxxldd7fsr44skme966tdckq8c: "Binance 3",
  terra1u0p7xuwlg0zsqgntagdkyjyumsegd8agzhug99: "Binance 4",
  terra1fax8l6srhew5tu2mavmu83js3v7vsqf9yr4fv7: "Binance 5",
  terra132wegs0kf9q65t9gsm3g2y06l98l2k4treepkq: "Binance 6",
  terra1l89hprfccqxgzzypjzy3fnp7vqpnkqg5vvqgjc: "Binance 7",
  terra1ns7lfvrxzter4d2yl9tschdwntcxa25vtsvd8a: "Binance 8",
  terra1vuvju6la7pj6t8d8zsx4g8ea85k2cg5u62cdhl: "Binance 9",
  terra1lzdux37s4anmakvg7pahzh03zlf43uveq83wh2: "Binance 10",
  terra1ky3qcf7v45n6hwfmkm05acwczvlq8ahnq778wf: "Binance 11",
  terra17m8tkde0mav43ckeehp537rsz4usqx5jayhf08: "Binance 12",
  terra1urj8va62jeygra7y3a03xeex49mjddh3eul0qa: "Binance 13",
  terra10wyptw59xc52l86pg86sy0xcm3nm5wg6a3cf7l: "Binance 14",
  terra1sujaqwaw7ls9fh6a4x7n06nv7fxx5xexwlnrkf: "Binance 15",
  terra1qg59nhvag222kp6fyzxt83l4sw02huymqnklww: "Binance 16",
  terra1dxxnwxlpjjkl959v5xrghx0dtvut60eef6vcch: "Binance 17",
  terra1y246m036et7vu69nsg4kapelj0tywe8vsmp34d: "Binance 18",
  terra1j39c9sjr0zpjnrfjtthuua0euecv7txavxvq36: "Binance 19",
  terra1t0jthtq9zhm4ldtvs9epp02zp23f355wu6zrzq: "Binance 20",
  terra12dxclvqrgt7w3s7gtwpdkxgymexv8stgqcr0yu: "Binance 21",
  terra1az3dsad74pwhylrrexnn5qylzj783uyww2s7xz: "Binance 22",
  terra1ttq26dq4egr5exmhd6gezerrxhlutx9u90uncn: "Binance 23",
  terra13e9670yuvfs06hctt9pmgjnz0yw28p0wgnhrqn: "Binance 24",
  terra1skmktm537pfaycgu9jx4fqryjt6pf77ycpesw0: "Binance 25",
  terra14q8cazgt58y2xkd26mlukemwth0cnvfqmgz2qk: "Binance 26",
  terra163vzxz9wwy320ccwy73qe6h33yzg2yhyvv5nsf: "Binance 27",
  terra1kj43wfnvrgc2ep94dgmwvnzv8vnkkxrxmrnhkp: "Binance 28",
  terra1gu6re549pn0mdpshtv75t3xugn347jghlhul73: "Binance 29",
  terra1gft3qujlq04yza3s2r238mql2yn3xxqepzt2up: "Binance 30",
  terra174pe7qe7g867spzdfs5f4rf9fuwmm42zf4hykf: "Binance 31",
  terra1ju68sg6k39t385sa0fazqvjgh6m6gkmsmp4lln: "Binance 32",
  terra1dlh7k4hcnsrvlfuzhdzx3ctynj7s8dde9zmdyd: "Binance 33",
  terra18wcdhpzpteharlkks5n6k7ent0mjyftvcpm6ee: "Binance 34",
  terra1xmkwsauuk3kafua9k23hrkfr76gxmwdfq5c09d: "Binance 35",
  terra1t957gces65xd6p8g4cuqnyd0sy5tzku59njydd: "Binance 36",
  terra1s4rd0y5e4gasf0krdm2w8sjhsmh030m74f2x9v: "Binance 37",
  terra15jya6ugxp65y80y5h82k4gv90pd7acv58xp6jj: "Binance 38",
  terra14yqy9warjkxyecda5kf5a68qlknf4ve4sh7sa6: "Binance 39",
  terra1yxras4z0fs9ugsg2hew9334k65uzejwcslyx0y: "Binance 40",
  terra1p0vl4s4gp46vy6dm352s2fgtw6hccypph7zc3u: "Binance 41",
  terra1hhj92twle9x8rjkr3yffujexsy5ldexak5rglz: "Binance 42",
  terra18vnrzlzm2c4xfsx382pj2xndqtt00rvhu24sqe: "Binance 43",
  terra1ncjg4a59x2pgvqy9qjyqprlj8lrwshm0wleht5: "Binance 44",
  terra19l7hzwazq5j0dykfldcwrk2927xwcjd0kt0vt9: "Binance 45",
  terra1frh79vmtur5fmrghz6gfjvfhpa3u2c0uemv4af: "Binance 46",
  terra1rvxcszyfecrt2v3a7md8p30hvu39kj6xf48w9e: "Kucoin 1",
  terra1chq5ps8yya004gsw4xz62pd4psr5hafe7kdt6d: "Kucoin 2",
  terra1v74a5u6qsjuj4gu6at9yn4p35uctcz82f02fau: "Kucoin 3",
  terra12amk3hgzqn85n0zlsmxp75cdcwt782w23t7acx: "Kucoin 4",
  terra13s4gwzxv6dycfctvddfuy6r3zm7d6zklynzzj5: "OKX 1",
  terra17xpfvakm2amg962yls6f84z3kell8c5lkaeqfa: "Fee Collector",
  terra1g9htux72h5nj5c0hpzely3rqwnryzmy22xlxpu: "DFLunc",
  terra16aumxyfeh2vrd2kr9qk2gadmwmg4jm5kw6nryp: "BurnItAll",
  terra1vwa6h9dx44lqq87f0wmsv0j5ghm2quqvctk7lt: "Tamashi gg",
  terra1wjmule8g0e73wehuxfp8mparnd2n4agut7nfh7: "Lunc Fire 1",
  terra1pzc9wyu5r4dtwrme9tntfymf2yskr6qrm7tk6k: "Lunc Fire 2",
  terra18kdk2kf8uvzs5gghky23hv4q7wdnk0ff2k83wt: "Garuda Universe",
  terra153mwt0upple9klvrryrtckx9vneguw6ja33d3c: "Terra Casino",
  terra1xusghqxnaxmnflqxtuh6jlumjfvapksw5ctnm3: "Air Force Lunc",
  terra13yex6xefup97382jq07se35at3vzrkrt8d7h5k: "GarudaX Token Burn",
  terra1k3202kcypl5csdtyvr3qjmxzzjnyn84ghax7wh: "Terrarium",
  terra1rsrfh4dvdzj0mjuay7adl6mdrfjtp3qadhlmwh: "Lunc Zombie Burn",
  terra1r2u8em5304u25xy0xw5ds7f40rcjqdd3e6pk06: "LBUNProject BASE Related 1",
  terra1gmlqv85qn4azff5fqxdrnwg0tj5cu6rhtd5jak: "LBUNProject BASE Related 2",
  terra1x7euya24k7m2erhv0z4p5fsm2a0x2l2n8q90e7: "LBUNProject BASE Related 3",
  terra16kfnaknle3q0m7jd2ldvzezkd5vle3n5waf2ps: "LBUNProject BASE Related 4",
  terra1kdlg55slelcaumhkz6wvqquslqcal7w5tjy4ez: "LBUNProject BASE Related 5",
  terra1yz3hv2k3a5z3tuu7fufcf7xvn2aawcu7c2fzdm: "LBUNProject BASE Related 6",
  terra17zwhrldqglg7cf83908psfjqfzn5s03z0732w5: "LBUNProject BASE Related 7",
  terra1kg6t9tkwzcnfdlwd2lklg8l0pwkl9vmuhg0x2s: "LBUNProject BASE Related 8",
  terra16mzrderk33ctu6w9amsvmmkt42sv66l4mallur: "LBUNProject BASE Related 9",
  terra1t3hgyjvunzdq4zuulvheh46h9usp06rm8f94ys: "LBUNProject BASE Related 10",
  terra1mqacptvr4v87xvcs7sd2sj6jnm2f95n7mecnwz: "LBUNProject BASE Related 11",
  terra1dq4kjhtg0vjlkkrwdkm0hr6s32aujnyp5aa5wz: "LUNC Cookies",
  terra1v43j7e5d58fceqg0awqyvraun4c4hlflda9sh8: "LUNC420",
  terra1cymh5ywgn4azak74h4gsrnakqgel4y9ssersvx: "LFG 1",
  terra1gr0xesnseevzt3h4nxr64sh5gk4dwrwgszx3nw: "LFG 2",
  terra1hgxxhyvjdpx06l28yx8u9dkpnx5a265duyx9tm: "LFG 3",
  terra1q0phshknw0pelzrwlezmt7vnmr8jh9vjgezt3z: "LFG 4",
  terra1qy36laaky2ns9n98naha2r0nvt3j7q3fpxfs2e: "LFG 5",
  terra100q9kymr3ragcftenzrwyn0s34jard347sqgyf: "TFL 1",
  terra10cn33pugeet6sgmdmswqckgka27gtt4n9l48vg: "TFL 2",
  terra10zzfuednzhkmwn4tqks2mukstr02r5jklpmh2f: "TFL 3",
  terra120fas4lu0p48lc4cwkhk487raxt87weu47cflv: "TFL 4",
  terra12jjl92hxfur0v4h5xu0nxlrxgnch3kge8yex99: "TFL 5",
  terra12qmfcxy8kahwt44tn4aq4t6kdmtyer38xnrh7m: "TFL 6",
  terra12vp8yq4wygfl9gssjs8k3kagrv3kx7ppexzqv2: "TFL 7",
  terra13t7x0glk0e6ln5zwdc8uudmylnhmmqf3tfqdc2: "TFL 8",
  terra13u3p30c2tn9m8dmru6mfyhmqs379qm43vrx7l7: "TFL 9",
  terra13u66u4knssnws7n7w0n38mzyyqak5ygp807gyl: "TFL 10",
  terra13vpm23s7rw5gqp9ckhqngjesxcuzatytknqpfg: "TFL 11",
  terra1404rdkfaj5ag8ukejtqutx0wszf44y6jwd7evq: "TFL 12",
  terra14l46jrdgdhaw4cejukx50ndp0hss95ekt2kfmw: "TFL 13",
  terra14ny376fe7hys2zqxwc87zp2achp9tju5q6jul4: "TFL 14",
  terra14tkf4veq2sj2q5fy0kf2xllz5hqwaf3efaatev: "TFL 15",
  terra14tlthgtg6ep6xnqptyg8dp3gcq4jxvgqmskwkd: "TFL 16",
  terra150nxdh7mnsz635y7yku7jatr7mx62lpr4pmajr: "TFL 17",
  terra15ffq0drrl5alm7txhel29jjuqpgsezprdc7f3g: "TFL 18",
  terra15med60egxczkvpj69v20jep09xw4e09v0tkgnf: "TFL 19",
  terra15n30nk24z4usykrmatk5phlq6dnv85vltdaqxm: "TFL 20",
  terra15nuyg54943gy2fj6u8cg8emeql8g6cpw2s2gnp: "TFL 21",
  terra15w7zj0sppxndaw9f2xr2maxtam9sd58r4fj27q: "TFL 22",
  terra16vs6rf0ldy8esfw0rjtefmsfj86sx2nwkf9vfz: "TFL 23",
  terra17kd2zh4dawqx23mp68jmau4geftmyz8vuccecw: "TFL 24",
  terra17llpzqmqqect6m3jun5ygkpgad9302l8c366wx: "TFL 25",
  terra17p4mqd7yl9m0r7cfv0nf9s9zae3d3gm4tmyg2a: "TFL 26",
  terra17slsfmtuavq9dw607aqu35v82pnkaavg3uywqk: "TFL 27",
  terra17uv6pjyfpnck0qvqe4kzwvf8cjy0cm7tn2vg30: "TFL 28",
  terra186y5e0vsg2c8upmdvsg3ler6f5vjvngdrsrcl2: "TFL 29",
  terra1885dgdvn5u8sjfaefvr39arssaxgqmd29ht0aa: "TFL 30",
  terra18kgwjqrm7mcnlzcy7l8h7awnn7fs2pvdl2tpm9: "TFL 31",
  terra18w7z9prrjzncz3mkqh7e65q3mc6j386y0qyw9a: "TFL 32",
  terra19f9uy05e22lmuwwas5y6gyegn0de5mnx294gqk: "TFL 33",
  terra19prvtg0g32x6044xw87nj2xpslgynads5qqqqq: "TFL 34",
  terra1a4hsgd8h4tapvzplafxk994sn8lcxa2lpv6g95: "TFL 35",
  terra1anllr0tt8r2my88kp6kmdwdnewnja5246djj99: "TFL 36",
  terra1asa47p67tgrl0858295k3wu8gvyypgc5eqre9w: "TFL 37",
  terra1ayg93fkswx733p6ga4yay9n28gns96d8n5238y: "TFL 38",
  terra1dp0taj85ruc299rkdvzp4z5pfg6z6swaed74e6: "TFL 39",
  terra1dtzfwgzt8xa70zkm8gqzwz0n4zrqtngdpqejx5: "TFL 40",
  terra1dvghtnsqr6eusxxhqcmuhwmpw26rze8kgap823: "TFL 41",
  terra1e028300g5kaef05vycatgz8tnrvmwmfstafk52: "TFL 42",
  terra1edl99rx5nfjq26mmydck4nr5w6enf3wwttrqn8: "TFL 43",
  terra1f4hkav2s46kf7jpaeu9nwrzy0upaa96el76k48: "TFL 44",
  terra1f6y8w7lyhe47uu34ru8gcwqmz2qp5czjkh0c05: "TFL 45",
  terra1f9033snquell52kxnv5cqauuq0c8gsf5cz30nv: "TFL 46",
  terra1fqgwjedr6wc63f5hasmurzc7kfqe6zqjz2ets7: "TFL 47",
  terra1fucr7wdhrm4q2wa73y4fygwc0qnlrqryqcukjv: "TFL 48",
  terra1g2ghu8s3c4thlta9v3zlnk5sr9jq7gzaymnjqw: "TFL 49",
  terra1g5dmf4qpmdrcw77d3ty6djrgujt8r2n3lgyknj: "TFL 50",
  terra1g9p9c5hjw4m3v9l65lmxt2g89vheashp6yfukm: "TFL 51",
  terra1grpgrd2ch2hqya24q99q7gcfk0pfsmq5tuq573: "TFL 52",
  terra1h5el75ha0zdwfawgxs6z0shqcw3m8r53f0wqrl: "TFL 53",
  terra1j20ryn8358egr3xux0a4qdpje9y5h7ed277k8a: "TFL 54",
  terra1jlvhvntwkkr5y46gl9duleftkxj7qaxw9t52ev: "TFL 55",
  terra1ju3vpfrurdt5ekm68zqaz2al2hjul2vyc2eeeq: "TFL 56",
  terra1k72puv50tjck4jxluq2qmjkjvvfpqe4y6ffgtl: "TFL 57",
  terra1kakd0l3ll92gamdz8zq7pr7lmghxhkna7jjj39: "TFL 58",
  terra1kflkz6dyc84snr5rwqx3dn8dd92m6lhqauhpen: "TFL 59",
  terra1kh3mdlm056je243kyv9glegvd494tf65vtq4lh: "TFL 60",
  terra1kvwkvurw4xexw69ef772p95jajnjjjsq6d5uca: "TFL 61",
  terra1kznvssy2wuzjq96d8esnwvpufsdf5vnejmwes7: "TFL 62",
  terra1l34cc5rc7lfp5f02mrx825w0n3rzpxjttjfrex: "TFL 63",
  terra1lcn74u0pysevu0nvcum5cl76xhwyk6jfjxrjj9: "TFL 64",
  terra1le5u9a5xsx6lzwa3sfahrmcqdhqc674tq7mtun: "TFL 65",
  terra1lewnh53gt0hzgjejrtwdz8q7dennz09s6ds4k2: "TFL 66",
  terra1lk052emj0a5xspuusqv7tls50hxcladp63p78w: "TFL 67",
  terra1ly9s5rpk0nn2sv8v5kxhrwkp3d48ytnxn6e5yj: "TFL 68",
  terra1mer7nd843n5g3v37xxtd3hude6t5rf4cq3mnae: "TFL 69",
  terra1mv8kytdmjtjflj67wml3f7fwglcm0tyksur2s7: "TFL 70",
  terra1n4s64hnryvll5494h0vyathctzxcgvxyvlzcve: "TFL 71",
  terra1ndmlkwnhwtq6ec2ww9na46w06pngww79z5722c: "TFL 73",
  terra1nexqdq252acp09m9eler7jhg9kd3rqfzkek9r0: "TFL 74",
  terra1njf8w6j6533e30mdmjs26qhg7hz3mvhcz0wfe6: "TFL 75",
  terra1np5l2y7y2jm5sdl2hklyjx0wm4swhmyufwfju9: "TFL 76",
  terra1pahtsf4340k0txffcmgn7hc9e9ujvfqjj32x26: "TFL 77",
  terra1pe5q4xlu5g8vtk944mmmz8kryrgtcqup5t4j26: "TFL 78",
  terra1pp8d7k749aahvdawpnry89auhdyu43ru6at93g: "TFL 79",
  terra1qa0eas8f496jhdkx5dp7r6qka7edvdpzmrqs4h: "TFL 80",
  terra1qmhrqjyjadep9p6qklp2kyzfen2kuwy06qnpjr: "TFL 81",
  terra1qry8z0ysw24498yf5dnqadextpjtt8wf4gjpzh: "TFL 82",
  terra1r3mdl2q46mx5z8y8d6enm35t6g76wqtn43008u: "TFL 83",
  terra1t28h4fg8gjpggvq985d2zz569qj8hpxnsxcx93: "TFL 84",
  terra1t60qeppglvwpa4ha8uhcavrct87lnuyyfnvg6r: "TFL 85",
  terra1t6gktppdd82wjnz3sm4792ycs44xc232773sae: "TFL 86",
  terra1t8szgklntcwxyfyflucuq895gpjr8wn6tsqxye: "TFL 87",
  terra1tdu6f7nffenxw7aahumtjklnzfcdw24k8836q5: "TFL 88",
  terra1tqqvkezdfhk6zpns2qawasn2legcnnsatxwdwt: "TFL 89",
  terra1tthcqld37wghyg3r5q5wj2f88tl5xqmcq4gweg: "TFL 90",
  terra1tuwmm8gy8e5sjza8nm2glpkfsl0h074ge7jh85: "TFL 91",
  terra1txdprxd6haeqmsv9w25m5lyznk9gc8wnvkk9ee: "TFL 92",
  terra1u9sanfs5qul9yupat9ctegy0kr4gsh7f5npzql: "TFL 93",
  terra1uhdaxsdkd05jypjuqypzyhelgtchnvue57rw5p: "TFL 94",
  terra1upvtdfdedsaupukkgug6zsn39y88cqty0c9qmy: "TFL 95",
  terra1uwvssna69j3yk7u6lhvnywleek43ajsgly7ycf: "TFL 96",
  terra1v49w0m38fe87edhqdv30w96wq0pz6u6548q9gj: "TFL 97",
  terra1v9ku44wycfnsucez6fp085f5fsksp47u9x8jr4: "TFL 98",
  terra1wlc64fe7p6u37qq0vykeksmr40d6a6cscfcn28: "TFL 99",
  terra1wqatrjkjgwc9998ft8kmrk2p78a0luexk5eckt: "TFL 100",
  terra1x04xgtwlw72gtfzrq7nfwmr6eexla8ecljw28z: "TFL 101",
  terra1xah6rv29d6ztfhkx7k2u8grpkmpket2pqyz49x: "TFL 102",
  terra1xmr5j80l7446zqrn2093vl7tl37ulsdv97y69k: "TFL 103",
  terra1xp0t0suwjfsm708ptxtfhheq2d72fsuehvttgu: "TFL 104",
  terra1xpcsvh9w4cg07rq9ne8pvgg5277e0jtpk0kjcp: "TFL 105",
  terra1xvmdjsdp58sanlzvnx6g79nyf2zk7cr5l78mxs: "TFL 106",
  terra1yca6ukeesx6p2393amm0y97m0qyqmntuzwenrx: "TFL 107",
  terra1ypl9vcyx4e9epgdevdqnc36dd3enxa0a7dh6pg: "TFL 108",
  terra1yrqggdm0zhgyr837h87xy3m00layz5g6yhsvfc: "TFL 109",
  terra1ywzl73n563gpdeh7dnqx79j3v9hhlj2fayct8e: "TFL 110",
  terra1yx9jy2rq4qp9fsuws4k6q8em2tpqqn5zkjfnwj: "TFL 111",
  terra1z2t3mmn0k8uzpzpspamy9pz0n97k8y7s6atxqv: "TFL 112",
  terra1z32l7kapluzykwv9p7cmrq6myqcxt2j3jzgr59: "TFL 113",
  terra1zaj0537x67s9vjtg06d2xu0fagvnqacrecq0zd: "TFL 114",
  terra1zd46n9v45lsqsxrcje3ke6ejlxm7nhtqr2dlgq: "TFL 115",
  terra1zkjyw4tszwq38rn6l083lt2u44j5fed69xgvke: "TFL 116",
  terra1zu47exa76mv20xt5hywhrs0r87wu52pnk5uxt5: "TFL 117",
  terra1zwh52hgtkmhqhha9aydmt70leuxujdnf8yfedn: "TFL 118",
  terra1zz2nf34fjkjygkg0kplkrr29ycxarmct6kafvj: "TFL 119",
  terra14s5eqfppup8ymjywf3devy8gy75nsrqkq3utjj: "BtcTurk 1",
  terra13yxhrk08qvdf5zdc9ss5mwsg5sf7zva9xrgwgc: "Shuttle 1",
  terra1cwnw4mxanhk47kuvunz067smvwgzwjfgpfuzjy: "Shuttle 2",
}

export const KNOWN_CONTRACT_ADDRESSES = {
  terra187cnk4ae0ynets4f638398zhehfhkuefrljgn0tyewlkpmulxq7sr6a3lr: "Terraport V2 Treasury",
  terra1vrqd7fkchyc7wjumn8fxly88z7kath4djjls3yc5th5g76f3543salu48s: "Terraport V2 Router",
  terra134ummlrj2rnv8h8rjhs6a54fng0xlg8wk7a2gwu6vj42pznkf6xs95966d: "Terraport V2 Staking",
  terra19v3vkpxsxeach4tpdklxaxc9wuwx65jqfs6jzm5cu5yz457hhgmsp4a48n: "Terraport V2 Vesting",
  terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp: "Terraport TERRA Token",
  terra1rlfns43umzqszm52txxmnseevffx2pe408c99m7cnvd828tdj67q9ftjs2: "Terraport LUNC-TERRA Pair",
  terra1p3xuq64hh47hdt0uf0ncy6pzfwplme62nu22vqmv0sk86zwy87uqpl0egn: "Terraport USTC-TERRA Pair",
  terra1sdwdgj80t295t7vfd89dj0pgm08pequmecpacmy8ur2x48l46xcstep3n6: "Terraport TERRA-USDC Pair",
  terra1l2fn5pqzkm06f8sc4p6j3zmyggmdgsntvee66lykm8ay9xfq8rpqgfpwvn: "Terraport LUNC-USTC Pair",
  terra17tk9v62lgvum67rs2mx98h6kz86m6m7lg5k0gnv407xhgc957z5qsp404w: "Terraport LUNC-USDC Pair",
  terra13w6cruzc0xkdhpmrnspr5j282x3wyx0m4f6kjnr65tpq63usn8eqp5emy4: "Terraport LUNC-JURIS Pair",
  terra18lzl6drsnyr6m9mplfw2fkr8kw6gsvar7wr8wzqgcx7xva58dhpsq7jgdy: "Terraport TERRA-JURIS Pair",
  terra1t9eleddtsrndxgjcu8n0vn0zne6tlar8n8r605p9089nkwj9v0js2xcun4: "Terraport LUNC-TKMN Pair",
  terra1x6027sw6punk7stmy4lptaeknannn367390dmgf0u9m7pnrdc4nq2h2ln9: "Terraport LUNC-EDU Pair",
  terra1l0zz4s97ljlphguu0082d34zzh434vrlg7de7k4m27lpdjhf2sxs2sluls: "Terraport LUNC-HMEME Pair",
  terra1u5tl9hf3q5uhtch5xan5rkf0w9awzn0klwffsphupykafm52n6jqr3ns8y: "Terraport LUNC-ROTTI Pair",
  terra13dmjw2dpj49nnazxq79p6uyfa9jd5rrte9tvcvqywmfaphvsfndqsre727: "Terraport LUNC-PIZZAB Pair",
  terra18pmu9k44juv7hnkwt0x2ppz2tmnt22lqepgry6pjdyf2hmhg082shq9s4l: "Terraport LUNC-KCK Pair",
  terra1han5md6ha92ntulv7c5zzh56q9ncj9vc9k53myyu2rmnqq2vpseqawmjh0: "Terraport LUNC-CatWifHat Pair",
  terra16ma4mvepnpdpjd6tumfszx5jud825skdfvpxeyc4q0zlqxcuhlgqrnqxeq: "Terraport LUNC-BOYS Pair",
  terra12u7khzrzn05a73xkpq6a5zrcazz2xmqn7lvupmqmca06pgcyt5qsa9e7p6: "Terraport LUNC-ALPHA Pair",
  terra1ht0fuwnurs5afln8u36nkjsdy4zcmuv3hnre54rgy2lf9286fxtqsaf4yu: "Terraport LUNC-FRG Pair",
  terra1x5ygs7pk6x0arh4kpfk5rvyx94ukyjs7xvvcyf8umqpkwqqnucas03f7su: "Terraport LUNC-COOKIE Pair",
  terra1l7vy20x940je7lskm6x9s839vjsmekz9k9mv7g: "Terraswap LUNA-UST Pair",
  terra14rfnhc2aru7jp304lht3yf903necsnrt4cxd464cftsfcksw0rtsgundnt: "Frogztrik NFT Token",
  terra12g4yx20fpeha7uts3l3lepnkycfldct6lpdh4gu6jjuhwxzv8lvsdz45re: "Tom's Burn Nft",
  terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m: "LBUNProject BASE Token",
  terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6: "LBUNProject cwLUNC Token",
  terra12qc2mah0eyzsppcmekelqrhuxaa8jwwht0lv07xvv2t9wnepk58sg28yh8: "LBUNProject BASE Related 12",
  terra1ggjadsdn285f4ae9wykle5lnawna7gdk32g6dfgpev8j0hx5jkpsc7u4gn: "LBUNProject BASE Related 13",
  terra12f3f5fzfzxckc0qlv3rmwwkjfhzevpwmx77345n0zuu2678vxf0sm6vvcw: "Garuda Universe GRDX Token",
  terra1j9q30fspe7tn0nrxpjfnzzdha4xfkyznluwnqrgxshtyv2tfe5kswnrj6c: "LUNC Warriors NFT LUNW Token",
  terra1g6fm3yu79gv0rc8067n2nnfpf0vks6n0wpzaf4u7w48tdrmj98zsy7uu00: "Terra Tritium Token",
  terra1rdm4u439w8lery29m5dv5z6w7raea0gvt29qefqhr37n5n4yhphsxlqpwc: "Terra Tritium TRIT Related 1",
  terra12z2273hfx0y5682gfdkm6rmupf3wn5mkgfstla3zazjsgxetkzpql05fm9: "Classic Bean Minting",
  terra1qyw695vaxj7jl6s4u564c6xkfe59kercg0h88w: "Risk Harbor Multisig",
  terra1xguz52m4zzescejrgd5mh86r8h40mf967n8ayn46g8sg45p3yleqfeqe68: "Mint Cash Burndrop",
  terra1c6xpgmeqap78vx7m42kh5dnyj9p77czxu7m7y37r5szc97ut2kjqhm7t6u: "Lunc Pepe",
  terra10nmmwe8r3g99a9newtqa7a75xfgs2e8z87r2sf: "Wormhole Token Bridge",
  terra1vhgq25vwuhdhn9xjll0rhl2s67jzw78a4g2t78y5kz89q9lsdskq2pxcj2: "Juris Token",
  terra1c0ehmz9wkacspxycs7ve2gdgwzcge0zr8weuupvzd8pt0w5qst2qgzcwst: "CatWifHat project Burns",
  terra14t6pjtm6nrrfdpm27flhput95w4cmrgp3dtp7uc94p33zsfvhmyq3eegdr: "Alpha Private Sale",
  terra1mkl973d34jsuv0whsfl43yw3sktm8kv7lgn35fhe6l88d0vvaukq5nq929: "Terraswap GRDX-LUNC Pair",
  terra12t88hh2nhasfzflgs6efl3ejhwedjnhfajstcku68dk2sxlv9yasz5f0a6: "Terraport GRDX-LUNC Pair",
  terra1x0d9yex70ngag7z5v7zcmsx6pk7fc6el5lt5qsaeycvqd7mq8vvqpak8v7: "Garuda Universe Hybrid Staking - GRDX",
  terra1q2dzpk5afxdz54shdtujax92a2zqz0m8jn2xt9qepzp59gcuy9kszdm4q8: "KingCryptoKong KCK Token",
  terra1m58hc296srr6xygrrfyu6u0e32pl8d459nfs55pmegke8zlv94rqsjz882: "iSkitzTV CatWifHat Token",
  terra1u9t44rwldu8ach2njqu3ppkfwc9s2g9kz54h0nr3dkzp2qx39ptsjchl5g: "Mooner Baron's Army (MBA)",
  terra14nfst294yvtdhrt4hk0njyszyh08q7e0g8rwknht7gyvw24ceyeqw42npg: "HOT Lady Original (HOTL)",
  terra16hzl7lge3jjnlazvut2ypt7upk68z5dycc9rwy608fah8q0y3fuslkenzc: "Garuda DEX Presale",
}

export const KNOWN_ADDRESSES = { ...KNOWN_ACCOUNT_ADDRESSES, ...KNOWN_CONTRACT_ADDRESSES }

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const TIME_BUCKETS = ['1h', '4h', 'D', 'W', 'M']
export const TIME_INTERVALS = ['1D', '7D', '1M', '1Y']

// use local api server in dev mode
export const getApiUrl = () => {
  let url = PROD_API_URL;

  try {
    if (process.env && process.env.NODE_ENV === 'development') {
      url = `http://${window.location.hostname}:8902`;
    }
  }
  catch (err) {
    url = PROD_API_URL;
  };

  return url;
}
